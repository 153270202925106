<script lang="jsx">
import { defineComponent } from 'vue'
import { template } from '@shein/common-function'
import { getUserAbtData } from '@shein-aidc/basis-abt-router'
import { expose } from 'public/src/pages/common/analysis/directive'
export default defineComponent({
  name: 'QuickShip',
  directives: {
    expose,
  },
  emits: ['updateQuickShip'],
  props: {
    mallCode: {
      type: String,
      default: '',
    },
    skuInfo: {
      type: Object,
      default: () => ({}),
    },
    skuList: {
      type: Array,
      default: () => [],
    },
    deliveryFloorQuickshipTitle: {
      type: String,
      default: ''
    },
    isJpQuickShip: {
      type: Boolean,
      default: false,
    },
    skcSaleAttr: {
      type: Array,
      default: () => [],
    },
    localSizeMap: {
      type: Object,
      default: () => ({})
    },
    cssRight: {
      type: Boolean,
      default: false,
    },
    language: {
      type: Object,
      default: () => ({}),
    },
    from: {
      type: String,
      default: '',
    },
    isNewSizeLocal: {
      type: Boolean,
      default: false,
    },
    hiddenPartialQuickShip: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showQuickShip: false,
    }
  },
  computed: {
    isSingleSaleAttr() {
      return this.skcSaleAttr.length == 1
    },
    processedSkuList(){
      return this.skuList?.map?.(sku => {
        if(this.isJpQuickShip && sku?.mall?.[this.mallCode]){
          sku.mall[this.mallCode].sku_quick_ship = true
        }
        return sku
      })
    },
    processedSkuInfo(){
      if(this.isJpQuickShip && this.skuInfo?.mall?.[this.mallCode]){
        return {
          ...this.skuInfo,
          mall: {
            ...this.skuInfo.mall,
            [this.mallCode]: {
              ...this.skuInfo.mall[this.mallCode],
              sku_quick_ship: true
            }
          }
        }
      }
      return this.skuInfo
    },
    quickShipSkuList() {
      return this.processedSkuList.filter((sku) => {
        return sku?.mall?.[this.mallCode]?.sku_quick_ship
      })
    },
    saleAttrName() {
      const attrNameList = this.skcSaleAttr.map((item) => {
        return item.attr_name
      })
      return attrNameList.join(' / ')
    },
    exposeData() {
      return {
        location: this.from == 'detailPage' ? 'detail' : 'popup',
        status: this.text.status,
      }
    },
    text() {
      if (this.quickShipSkuList.length) {
        if (this.processedSkuList.length == 1) {
          return {
            p: this.calcQuickshipText({ zeroValue: '', langV1: this.language.SHEIN_KEY_PWA_22316, langV2: this.language.SHEIN_KEY_PWA_30897 }),
            status: 1,
          }
        }
        if (this.processedSkuInfo.sku_code) {
          // 选中了sku
          const attrValueList = this.skcSaleAttr.map((item) => {
            const skuAttrItem = this.processedSkuInfo.sku_sale_attr.find((_) => _.attr_id == item.attr_id)
            const attrValueItem = item.attr_value_list.find(
              (_) => _.attr_value_id == skuAttrItem.attr_value_id
            )
            return this.getText(attrValueItem)
          })
          const langV1 = this.language[this.processedSkuInfo.mall?.[this.mallCode]?.sku_quick_ship ? 'SHEIN_KEY_PWA_22314' : 'SHEIN_KEY_PWA_22315']
          const langV2 = this.language[this.processedSkuInfo.mall?.[this.mallCode]?.sku_quick_ship ? 'SHEIN_KEY_PWA_30889' : 'SHEIN_KEY_PWA_30890']
          return {
            p: this.calcQuickshipText({ zeroValue: `<b>${attrValueList.join(' / ')}</b>`, langV1, langV2 }),
            status: this.processedSkuInfo?.mall?.[this.mallCode]?.sku_quick_ship ? 1 : 3,
          }
        } else {
          // 没选中sku
          if (this.processedSkuList.length == this.quickShipSkuList.length) {
            // 全部支持快速发货
            return {
              p: this.hiddenPartialQuickShip ? '' : this.calcQuickshipText({ zeroValue: this.saleAttrName, langV1: this.language.SHEIN_KEY_PWA_22311, langV2: this.language.SHEIN_KEY_PWA_30884 }),
              status: 1,
            }
          } else {
            let p = ''
            if (this.isSingleSaleAttr && this.quickShipSkuList.length <= 3) {
              const attrValueList = this.quickShipSkuList.map((item) => {
                const attrValueId = item.sku_sale_attr[0].attr_value_id
                const attrValueItem = this.skcSaleAttr[0].attr_value_list.find(
                  (_) => _.attr_value_id == attrValueId
                )
                return this.getText(attrValueItem)
              })
              const langV1 = this.language[this.quickShipSkuList.length == 1 ? 'SHEIN_KEY_PWA_22539' : 'SHEIN_KEY_PWA_22312']
              const langV2 = this.language[this.quickShipSkuList.length == 1 ? 'SHEIN_KEY_PWA_30885' : 'SHEIN_KEY_PWA_30887']
              p = this.calcQuickshipText({ zeroValue: attrValueList.join(', '), langV1, langV2 })
            } else {
              // 单级销售属性情况下：如果支持的SKU数量＞3个 || 多级销售属性情况下
              p = this.hiddenPartialQuickShip ? '' : this.calcQuickshipText({ zeroValue: this.saleAttrName, langV1: this.language.SHEIN_KEY_PWA_22313, langV2: this.language.SHEIN_KEY_PWA_30888 })
            }
            return {
              p,
              status: 1,
            }
          }
        }
      }
      return {
        p: '',
        status: 0,
      }
    },
  },
  async mounted() {
    const { DetailPageQuickShipShow } = await getUserAbtData()
    this.showQuickShip = DetailPageQuickShipShow?.param == 'DetailShowQuick'
  },
  methods: {
    getText(attrValueItem) {
      // const { attr_std_value, attr_value_name, correspond } = attrValueItem
      const { attr_std_value, attr_value_name } = attrValueItem || {}
      const correspond = this.localSizeMap?.[attr_value_name] || ''
      let attrText = attr_std_value
        ? this.cssRight
          ? attr_value_name + ' / ' + attr_std_value
          : attr_std_value + attr_value_name
        : attr_value_name
      if (correspond) {
        if (this.isNewSizeLocal) {
          return `${correspond}(${attrText})`
        } else {
          return `${attrText}(${correspond})`
        }
      } else {
        return attrText
      }
    },
    calcQuickshipText({ zeroValue = '', langV1, langV2, }) { // 处理quickship强化文案
      if(this.deliveryFloorQuickshipTitle) { // 命中强化实验 返回新文案
        const quickshipTextDom = `<span class="text-green">${this.deliveryFloorQuickshipTitle}</span>`
        return zeroValue ? template(zeroValue, quickshipTextDom, langV2) : this.template(quickshipTextDom, langV2)
      }
      return template(zeroValue, langV1)  // 返回旧文案
    },
  },
  render() {
    this.$emit('updateQuickShip', this.text.status)
    const { showQuickShip, text, language, exposeData, deliveryFloorQuickshipTitle } = this
    const showStatus = showQuickShip && text.p
    const contentEl = showStatus ? (
      <div class="goodsd-sizes__quickship">
        {!deliveryFloorQuickshipTitle ? (<span class={['title', { grey: text.status != 1 }]}>{language.SHEIN_KEY_PWA_22276}</span>) : null}
        <span
          v-expose={{ id: '1-6-1-78', data: exposeData }}
          innerHTML={text.p}
        ></span>
      </div>
    ) : null

    return contentEl
  },
})
</script>

<style lang="less">
.goodsd-sizes {
  &__quickship {
    color: @sui_color_gray_dark2;
    .font-dpr(24px);
    line-height: 0.48rem;
    overflow: hidden;
    margin-bottom: .16rem;
    b {
      font-weight: bold;
      color: @sui_color_gray_dark1;
    }
    .title {
      float: left;
      .font-dpr(20px);
      background: @sui_color_safety_bg;
      color: @sui_color_safety;
      padding: 0 0.106667rem;
      margin-right: 0.106667rem;
    }
    .grey {
      color: @sui_color_gray_light1;
      background: @sui_color_gray_weak2;
    }
    .text-green {
      color: @sui_color_success;
    }
  }
}
</style>
